.navigation-inner {
  background: #fff;
  border-radius: 3px;
  color: #a08ca8;
  font-weight: bold;
  position: fixed;
  width: 100%;
  z-index: 10;
}

.toggle-side-bar {
  align-self: center;
}
